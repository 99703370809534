export const SALON_REQUEST = 'SALON_REQUEST';
export const SALON_SUCCESS = 'SALON_SUCCESS';
export const SALON_FAIL = 'SALON_FAIL';
export const SALON_RESET = 'SALON_RESET';

export const DELETE_SALON_REQUEST = 'DELETE_SALON_REQUEST';
export const DELETE_SALON_SUCCESS = 'DELETE_SALON_SUCCESS';
export const DELETE_SALON_FAIL = 'DELETE_SALON_FAIL';
export const DELETE_SALON_RESET = 'DELETE_SALON_RESET';

export const CREATE_NEW_SALON_REQUEST = 'CREATE_NEW_SALON_REQUEST';
export const CREATE_NEW_SALON_SUCCESS = 'CREATE_NEW_SALON_SUCCESS';
export const CREATE_NEW_SALON_FAIL = 'CREATE_NEW_SALON_FAIL';
export const CREATE_NEW_SALON_RESET = 'CREATE_NEW_SALON_RESET';


export const UPDATE_SALON_REQUEST = 'UPDATE_SALON_REQUEST';
export const UPDATE_SALON_SUCCESS = 'UPDATE_SALON_SUCCESS';
export const UPDATE_SALON_FAIL = 'UPDATE_SALON_FAIL';
export const UPDATE_SALON_RESET = 'UPDATE_SALON_RESET';



export const CHANGE_STATUS_SALON_REQUEST = 'CHANGE_STATUS_SALON_REQUEST';
export const CHANGE_STATUS_SALON_SUCCESS = 'CHANGE_STATUS_SALON_SUCCESS';
export const CHANGE_STATUS_SALON_FAIL = 'CHANGE_STATUS_SALON_FAIL';
export const CHANGE_STATUS_SALON_RESET = 'CHANGE_STATUS_SALON_RESET';



export const GET_ALL_ENABLE_SALON_REQUEST = 'GET_ALL_ENABLE_SALON_REQUEST';
export const GET_ALL_ENABLE_SALON_SUCCESS = 'GET_ALL_ENABLE_SALON_SUCCESS';
export const GET_ALL_ENABLE_SALON_FAIL = 'GET_ALL_ENABLE_SALON_FAIL';
export const GET_ALL_ENABLE_SALON_RESET = 'GET_ALL_ENABLE_SALON_RESET';


export const CHANGE_MENU_SETTINGS_REQUEST = 'CHANGE_MENU_SETTINGS_REQUEST';
export const CHANGE_MENU_SETTINGS_SUCCESS = 'CHANGE_MENU_SETTINGS_SUCCESS';
export const CHANGE_MENU_SETTINGS_FAIL = 'CHANGE_MENU_SETTINGS_FAIL';
export const CHANGE_MENU_SETTINGS_RESET = 'CHANGE_MENU_SETTINGS_RESET';

