export const ADD_WEBSITE_SETTING_REQUEST = 'ADD_WEBSITE_SETTING_REQUEST';
export const ADD_WEBSITE_SETTING_SUCCESS = 'ADD_WEBSITE_SETTING_SUCCESS';
export const ADD_WEBSITE_SETTING_FAIL = 'ADD_WEBSITE_SETTING_FAIL';
export const ADD_WEBSITE_SETTING_RESET = 'ADD_WEBSITE_SETTING_RESET';

export const GET_WEBSITE_SETTING_REQUEST = 'GET_WEBSITE_SETTING_REQUEST';
export const GET_WEBSITE_SETTING_SUCCESS = 'GET_WEBSITE_SETTING_SUCCESS';
export const GET_WEBSITE_SETTING_FAIL = 'GET_WEBSITE_SETTING_FAIL';
export const GET_WEBSITE_SETTING_RESET = 'GET_WEBSITE_SETTING_RESET';

export const GET_WEBSITE_SERVICE_REQUEST = 'GET_WEBSITE_SERVICE_REQUEST';
export const GET_WEBSITE_SERVICE_SUCCESS = 'GET_WEBSITE_SERVICE_SUCCESS';
export const GET_WEBSITE_SERVICE_FAIL = 'GET_WEBSITE_SERVICE_FAIL';
export const GET_WEBSITE_SERVICE_RESET = 'GET_WEBSITE_SERVICE_RESET';

export const ADD_WEBSITE_CONTACT_US_REQUEST = 'ADD_WEBSITE_CONTACT_US_REQUEST';
export const ADD_WEBSITE_CONTACT_US_SUCCESS = 'ADD_WEBSITE_CONTACT_US_SUCCESS';
export const ADD_WEBSITE_CONTACT_US_FAIL = 'ADD_WEBSITE_CONTACT_US_FAIL';
export const ADD_WEBSITE_CONTACT_US_RESET = 'ADD_WEBSITE_CONTACT_US_RESET';