import axios from 'axios';
import { BASE_URL } from './ip';
import { USER_LOGIN_REQUEST } from '../constants/userConstants';

export const rewardsInfo = (formData) => async (dispatch, getState)  => {
    try {
        
		const { userLogin: { userInfo } } = getState();

    	const config = {
			headers: {
				token: userInfo.data.token,
				'Content-Type': 'application/json'
			}
		};

  
      const { data } = await axios.get(`${BASE_URL}/users/getRewardInfo`, config);
      console.log(data, 'data')
    //   dispatch({
    //     type: USER_LOGIN_SUCCESS,
    //     payload: data,
    //   });
  
    } catch (err) {
    //   dispatch({
    //     type: USER_LOGIN_FAIL,
    //     payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    //   });
    }
  };
  