export const GET_ALL_VIDEO_CATEGORY_REQUEST = 'GET_ALL_VIDEO_CATEGORY_REQUEST';
export const GET_ALL_VIDEO_CATEGORY_SUCCESS = 'GET_ALL_VIDEO_CATEGORY_SUCCESS';
export const GET_ALL_VIDEO_CATEGORY_FAIL = 'GET_ALL_VIDEO_CATEGORY_FAIL';
export const GET_ALL_VIDEO_CATEGORY_RESET = 'GET_ALL_VIDEO_CATEGORY_RESET';

export const ADD_VIDEO_CATEGORY_REQUEST = 'ADD_VIDEO_CATEGORY_REQUEST';
export const ADD_VIDEO_CATEGORY_SUCCESS = 'ADD_VIDEO_CATEGORY_SUCCESS';
export const ADD_VIDEO_CATEGORY_FAIL = 'ADD_VIDEO_CATEGORY_FAIL';
export const ADD_VIDEO_CATEGORY_RESET = 'ADD_VIDEO_CATEGORY_RESET';

export const VIDEO_CATEGORY_STATUS_REQUEST = 'VIDEO_CATEGORY_STATUS_REQUEST';
export const VIDEO_CATEGORY_STATUS_SUCCESS = 'VIDEO_CATEGORY_STATUS_SUCCESS';
export const VIDEO_CATEGORY_STATUS_FAIL = 'VIDEO_CATEGORY_STATUS_FAIL';
export const VIDEO_CATEGORY_STATUS_RESET = 'VIDEO_CATEGORY_STATUS_RESET';

export const DELETE_VIDEO_CATEGORY_REQUEST = 'DELETE_VIDEO_CATEGORY_REQUEST';
export const DELETE_VIDEO_CATEGORY_SUCCESS = 'DELETE_VIDEO_CATEGORY_SUCCESS';
export const DELETE_VIDEO_CATEGORY_FAIL = 'DELETE_VIDEO_CATEGORY_FAIL';
export const DELETE_VIDEO_CATEGORY_RESET = 'DELETE_VIDEO_CATEGORY_RESET';

export const GET_ALL_VIDEOS_REQUEST = 'GET_ALL_VIDEOS_REQUEST';
export const GET_ALL_VIDEOS_SUCCESS = 'GET_ALL_VIDEOS_SUCCESS';
export const GET_ALL_VIDEOS_FAIL = 'GET_ALL_VIDEOS_FAIL';
export const GET_ALL_VIDEOS_RESET = 'GET_ALL_VIDEOS_RESET';

export const GET_ONE_VIDEO_REQUEST = 'GET_ONE_VIDEO_REQUEST';
export const GET_ONE_VIDEO_SUCCESS = 'GET_ONE_VIDEO_SUCCESS';
export const GET_ONE_VIDEO_FAIL = 'GET_ONE_VIDEO_FAIL';
export const GET_ONE_VIDEO_RESET = 'GET_ONE_VIDEO_RESET';

export const CREATE_VIDEO_REQUEST = 'CREATE_VIDEO_REQUEST';
export const CREATE_VIDEO_SUCCESS = 'CREATE_VIDEO_SUCCESS';
export const CREATE_VIDEO_FAIL = 'CREATE_VIDEO_FAIL';
export const CREATE_VIDEO_RESET = 'CREATE_VIDEO_RESET';

export const CHANGE_VIDEO_STATUS_REQUEST = 'CHANGE_VIDEO_STATUS_REQUEST';
export const CHANGE_VIDEO_STATUS_SUCCESS = 'CHANGE_VIDEO_STATUS_SUCCESS';
export const CHANGE_VIDEO_STATUS_FAIL = 'CHANGE_VIDEO_STATUS_FAIL';
export const CHANGE_VIDEO_STATUS_RESET = 'CHANGE_VIDEO_STATUS_RESET';

export const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAIL = 'DELETE_VIDEO_FAIL';
export const DELETE_VIDEO_RESET = 'DELETE_VIDEO_RESET';
