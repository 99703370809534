export const STYLER_REQUEST = 'STYLER_REQUEST';
export const STYLER_SUCCESS = 'STYLER_SUCCESS';
export const STYLER_FAIL = 'STYLER_FAIL';
export const STYLER_RESET = 'STYLER_RESET';

export const DELETE_STYLER_REQUEST = 'DELETE_STYLER_REQUEST';
export const DELETE_STYLER_SUCCESS = 'DELETE_STYLER_SUCCESS';
export const DELETE_STYLER_FAIL = 'DELETE_STYLER_FAIL';
export const DELETE_STYLER_RESET = 'DELETE_STYLER_RESET';

export const CREATE_NEW_STYLER_REQUEST = 'CREATE_NEW_STYLER_REQUEST';
export const CREATE_NEW_STYLER_SUCCESS = 'CREATE_NEW_STYLER_SUCCESS';
export const CREATE_NEW_STYLER_FAIL = 'CREATE_NEW_STYLER_FAIL';
export const CREATE_NEW_STYLER_RESET = 'CREATE_NEW_STYLER_RESET';


export const UPDATE_STYLER_REQUEST = 'UPDATE_STYLER_REQUEST';
export const UPDATE_STYLER_SUCCESS = 'UPDATE_STYLER_SUCCESS';
export const UPDATE_STYLER_FAIL = 'UPDATE_STYLER_FAIL';
export const UPDATE_STYLER_RESET = 'UPDATE_STYLER_RESET';



export const CHANGE_STATUS_STYLER_REQUEST = 'CHANGE_STATUS_STYLER_REQUEST';
export const CHANGE_STATUS_STYLER_SUCCESS = 'CHANGE_STATUS_STYLER_SUCCESS';
export const CHANGE_STATUS_STYLER_FAIL = 'CHANGE_STATUS_STYLER_FAIL';
export const CHANGE_STATUS_STYLER_RESET = 'CHANGE_STATUS_STYLER_RESET';



export const GET_ENABLE_STYLER_LIST_REQUEST = 'GET_ENABLE_STYLER_LIST_REQUEST';
export const GET_ENABLE_STYLER_LIST_SUCCESS = 'GET_ENABLE_STYLER_LIST_SUCCESS';
export const GET_ENABLE_STYLER_LIST_FAIL = 'GET_ENABLE_STYLER_LIST_FAIL';
export const GET_ENABLE_STYLER_LIST_RESET = 'GET_ENABLE_STYLER_LIST_RESET';



export const CREATE_STYLIST_SETTINGS_REQUEST = 'CREATE_STYLIST_SETTINGS_REQUEST';
export const CREATE_STYLIST_SETTINGS_SUCCESS = 'CREATE_STYLIST_SETTINGS_SUCCESS';
export const CREATE_STYLIST_SETTINGS_FAIL = 'CREATE_STYLIST_SETTINGS_FAIL';
export const CREATE_STYLIST_SETTINGS_RESET = 'CREATE_STYLIST_SETTINGS_RESET';


export const GET_STYLIST_SETTINGS_REQUEST = 'GET_STYLIST_SETTINGS_REQUEST';
export const GET_STYLIST_SETTINGS_SUCCESS = 'GET_STYLIST_SETTINGS_SUCCESS';
export const GET_STYLIST_SETTINGS_FAIL = 'GET_STYLIST_SETTINGS_FAIL';
export const GET_STYLIST_SETTINGS_RESET = 'GET_STYLIST_SETTINGS_RESET';
