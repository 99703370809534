import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuSettingsAction } from '../../redux/actions/salonActions';
import Content from '../../components/Content/Content';
import AppSettingStyles from './AppSetting.module.css';
import { getDynamicFlags, updateDynamicDeleteAccountFlag, updateDynamicShopFlag } from '../../redux/actions/userActions';

const AppSettingScreen = () => {
	const [token, setToken] = useState('');
	const [isDeleteAccountEnabled, setIsDeleteAccountEnabled] = useState(false);
	const [isShopScreenEnabled, setIsShopScreenEnabled] = useState(false);
	const userLogin = useSelector((state) => state.userLogin);
	const getUserInfo = useSelector((state) => state.getUserInfo);
	const getDynamicFlagsReducer = useSelector((state) => state.getDynamicFlagsReducer)
	const dispatch = useDispatch();

	useEffect(() => {
		if (userLogin && userLogin.userInfo && userLogin.userInfo.status && userLogin.userInfo.data) {
			setToken(userLogin.userInfo.data.token);
		}
	}, [userLogin]);

	useEffect(() => {
		dispatch(getDynamicFlags());
	}, [dispatch]);

	const handleDeleteAccountToggle = () => {
		const newStatus = !isDeleteAccountEnabled;

		dispatch(updateDynamicDeleteAccountFlag({
			flag: newStatus
		}));
		setIsDeleteAccountEnabled(newStatus);
	};

	const handleShopScreenToggle = () => {
		const newStatus = !isShopScreenEnabled;

		dispatch(updateDynamicShopFlag({
			flag: newStatus
		}));
		setIsShopScreenEnabled(newStatus);
	};

	useEffect(() => {
		if (getDynamicFlagsReducer && getDynamicFlagsReducer.salons && getDynamicFlagsReducer.salons.data) {
			const dynamicFlags = getDynamicFlagsReducer.salons.data;
			const deleteAccountFlag = dynamicFlags.find(flag => flag.name === "accountDeleteButton");
			const shopScreenFlag = dynamicFlags.find(flag => flag.name === "shopButton");
			if (deleteAccountFlag) {
				setIsDeleteAccountEnabled(deleteAccountFlag.isEnabled);
			}
			if (shopScreenFlag) {
				setIsShopScreenEnabled(shopScreenFlag.isEnabled);
			}
		}
	}, [getDynamicFlagsReducer]);

	return (
		<Content currentMenu="app-settings" headerTitle="App Settings" addBtn={false}>
			<div className={AppSettingStyles.newScreenCardContainer}>
				<div className={AppSettingStyles.newScreenCard}>
					<span className={AppSettingStyles.cardTitle}>Delete Account</span>
					<label className="switch">
						<input
							checked={isDeleteAccountEnabled}
							onChange={handleDeleteAccountToggle}
							type="checkbox"
							className="checkbox"
							name="active"
						/>
						<span className="slider round" />
					</label>
				</div>
				<div className={AppSettingStyles.newScreenCard}>
					<span className={AppSettingStyles.cardTitle}>Shop Screen</span>
					<label className="switch">
						<input
							checked={isShopScreenEnabled}
							onChange={handleShopScreenToggle}
							type="checkbox"
							className="checkbox"
							name="active"
						/>
						<span className="slider round" />
					</label>
				</div>
			</div>
		</Content>
	);
};

export default AppSettingScreen;
