export const GET_DAILY_AVAILABILITYS_REQUEST = 'GET_DAILY_AVAILABILITYS_REQUEST';
export const GET_DAILY_AVAILABILITYS_SUCCESS = 'GET_DAILY_AVAILABILITYS_SUCCESS';
export const GET_DAILY_AVAILABILITYS_FAIL = 'GET_DAILY_AVAILABILITYS_FAIL';
export const GET_DAILY_AVAILABILITYS_RESET = 'GET_DAILY_AVAILABILITYS_RESET';

export const ADD_DAILY_AVAILABILITYS_REQUEST = 'ADD_DAILY_AVAILABILITYS_REQUEST';
export const ADD_DAILY_AVAILABILITYS_SUCCESS = 'ADD_DAILY_AVAILABILITYS_SUCCESS';
export const ADD_DAILY_AVAILABILITYS_FAIL = 'ADD_DAILY_AVAILABILITYS_FAIL';
export const ADD_DAILY_AVAILABILITYS_RESET = 'ADD_DAILY_AVAILABILITYS_RESET';

export const ADD_BULK_AVAILABILITYS_REQUEST = 'ADD_BULK_AVAILABILITYS_REQUEST';
export const ADD_BULK_AVAILABILITYS_SUCCESS = 'ADD_BULK_AVAILABILITYS_SUCCESS';
export const ADD_BULK_AVAILABILITYS_FAIL = 'ADD_BULK_AVAILABILITYS_FAIL';
export const ADD_BULK_AVAILABILITYS_RESET = 'ADD_BULK_AVAILABILITYS_RESET';


export const ADD_DAY_AVAILABILITYS_REQUEST = 'ADD_DAY_AVAILABILITYS_REQUEST';
export const ADD_DAY_AVAILABILITYS_SUCCESS = 'ADD_DAY_AVAILABILITYS_SUCCESS';
export const ADD_DAY_AVAILABILITYS_FAIL = 'ADD_DAY_AVAILABILITYS_FAIL';
export const ADD_DAY_AVAILABILITYS_RESET = 'ADD_DAY_AVAILABILITYS_RESET';


export const GET_UNBLOCK_AVAILABILITYS_REQUEST = 'GET_UNBLOCK_AVAILABILITYS_REQUEST';
export const GET_UNBLOCK_AVAILABILITYS_SUCCESS = 'GET_UNBLOCK_AVAILABILITYS_SUCCESS';
export const GET_UNBLOCK_AVAILABILITYS_FAIL = 'GET_UNBLOCK_AVAILABILITYS_FAIL';
export const GET_UNBLOCK_AVAILABILITYS_RESET = 'GET_UNBLOCK_AVAILABILITYS_RESET';

export const DEL_BLOCK_AVAILABILITYS_REQUEST = 'DEL_BLOCK_AVAILABILITYS_REQUEST';
export const DEL_BLOCK_AVAILABILITYS_SUCCESS = 'DEL_BLOCK_AVAILABILITYS_SUCCESS';
export const DEL_BLOCK_AVAILABILITYS_FAIL = 'DEL_BLOCK_AVAILABILITYS_FAIL';
export const DEL_BLOCK_AVAILABILITYS_RESET = 'DEL_BLOCK_AVAILABILITYS_RESET';
