export const GET_ALL_COUPONS_REQUEST = 'GET_ALL_COUPONS_REQUEST';
export const GET_ALL_COUPONS_SUCCESS = 'GET_ALL_COUPONS_SUCCESS';
export const GET_ALL_COUPONS_FAIL = 'GET_ALL_COUPONS_FAIL';
export const GET_ALL_COUPONS_RESET = 'GET_ALL_COUPONS_RESET';

export const ADD_COUPON_REQUEST = 'ADD_COUPON_REQUEST';
export const ADD_COUPON_SUCCESS = 'ADD_COUPON_SUCCESS';
export const ADD_COUPON_FAIL = 'ADD_COUPON_FAIL';
export const ADD_COUPON_RESET = 'ADD_COUPON_RESET';

export const ACTIVATE_COUPON_REQUEST = 'ACTIVATE_COUPON_REQUEST';
export const ACTIVATE_COUPON_SUCCESS = 'ACTIVATE_COUPON_SUCCESS';
export const ACTIVATE_COUPON_FAIL = 'ACTIVATE_COUPON_FAIL';
export const ACTIVATE_COUPON_RESET = 'ACTIVATE_COUPON_RESET';

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_FAIL = 'DELETE_COUPON_FAIL';
export const DELETE_COUPON_RESET = 'DELETE_COUPON_RESET';

export const VERIFY_COUPON_REQUEST = 'VERIFY_COUPON_REQUEST';
export const VERIFY_COUPON_SUCCESS = 'VERIFY_COUPON_SUCCESS';
export const VERIFY_COUPON_FAIL = 'VERIFY_COUPON_FAIL';
export const VERIFY_COUPON_RESET = 'VERIFY_COUPON_RESET';

export const GET_ADMIN_COUPONS_REQUEST = 'GET_ADMIN_COUPONS_REQUEST';
export const GET_ADMIN_COUPONS_SUCCESS = 'GET_ADMIN_COUPONS_SUCCESS';
export const GET_ADMIN_COUPONS_FAIL = 'GET_ADMIN_COUPONS_FAIL';
export const GET_ADMIN_COUPONS_RESET = 'GET_ADMIN_COUPONS_RESET';
