export const SALON_STAFF_REQUEST = 'SALON_STAFF_REQUEST';
export const SALON_STAFF_SUCCESS = 'SALON_STAFF_SUCCESS';
export const SALON_STAFF_FAIL = 'SALON_STAFF_FAIL';
export const SALON_STAFF_RESET = 'SALON_STAFF_RESET'; 

export const DELETE_SALON_STAFF_REQUEST = 'DELETE_SALON_STAFF_REQUEST';
export const DELETE_SALON_STAFF_SUCCESS = 'DELETE_SALON_STAFF_SUCCESS';
export const DELETE_SALON_STAFF_FAIL = 'DELETE_SALON_STAFF_FAIL';
export const DELETE_SALON_STAFF_RESET = 'DELETE_SALON_STAFF_RESET'; 

export const CREATE_NEW_SALON_STAFF_REQUEST = 'CREATE_NEW_SALON_STAFF_REQUEST';
export const CREATE_NEW_SALON_STAFF_SUCCESS = 'CREATE_NEW_SALON_STAFF_SUCCESS';
export const CREATE_NEW_SALON_STAFF_FAIL = 'CREATE_NEW_SALON_STAFF_FAIL';
export const CREATE_NEW_SALON_STAFF_RESET = 'CREATE_NEW_SALON_STAFF_RESET'; 
 
export const UPDATE_SALON_STAFF_REQUEST = 'UPDATE_SALON_STAFF_REQUEST';
export const UPDATE_SALON_STAFF_SUCCESS = 'UPDATE_SALON_STAFF_SUCCESS';
export const UPDATE_SALON_STAFF_FAIL = 'UPDATE_SALON_STAFF_FAIL';
export const UPDATE_SALON_STAFF_RESET = 'UPDATE_SALON_STAFF_RESET';  


export const CHANGE_STATUS_SALON_STAFF_REQUEST = 'CHANGE_STATUS_SALON_STAFF_REQUEST';
export const CHANGE_STATUS_SALON_STAFF_SUCCESS = 'CHANGE_STATUS_SALON_STAFF_SUCCESS';
export const CHANGE_STATUS_SALON_STAFF_FAIL = 'CHANGE_STATUS_SALON_STAFF_FAIL';
export const CHANGE_STATUS_SALON_STAFF_RESET = 'CHANGE_STATUS_SALON_STAFF_RESET';
 