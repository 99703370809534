import React from 'react';
import './PageNotFound.css';

const PageNotFoundScreen = () => {
	return (
		<div className="slider1">
		 
		</div>
	);
};

export default PageNotFoundScreen;
