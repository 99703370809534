
export const CREATE_NEW_ONBOARD_USER_REQUEST = 'CREATE_NEW_ONBOARD_USER_REQUEST';
export const CREATE_NEW_ONBOARD_USER_SUCCESS = 'CREATE_NEW_ONBOARD_USER_SUCCESS';
export const CREATE_NEW_ONBOARD_USER_FAIL = 'CREATE_NEW_ONBOARD_USER_FAIL';
export const CREATE_NEW_ONBOARD_USER_RESET = 'CREATE_NEW_ONBOARD_USER_RESET';

export const PAYMENT_ONBOARD_REQUEST = 'PAYMENT_ONBOARD_REQUEST';
export const PAYMENT_ONBOARD_SUCCESS = 'PAYMENT_ONBOARD_SUCCESS';
export const PAYMENT_ONBOARD_FAIL = 'PAYMENT_ONBOARD_FAIL';
export const PAYMENT_ONBOARD_RESET = 'PAYMENT_ONBOARD_RESET';

export const ONBOARD_DONE_REQUEST = 'ONBOARD_DONE_REQUEST';
export const ONBOARD_DONE_SUCCESS = 'ONBOARD_DONE_SUCCESS';
export const ONBOARD_DONE_FAIL = 'ONBOARD_DONE_FAIL';
export const ONBOARD_DONE_RESET = 'ONBOARD_DONE_RESET';