export const ADD_APPOINTMENT_REQUEST = 'ADD_APPOINTMENT_REQUEST';
export const ADD_APPOINTMENT_SUCCESS = 'ADD_APPOINTMENT_SUCCESS';
export const ADD_APPOINTMENT_FAIL = 'ADD_APPOINTMENT_FAIL';
export const ADD_APPOINTMENT_RESET = 'ADD_APPOINTMENT_RESET';

export const ADD_PUBLIC_APPOINTMENT_REQUEST = 'ADD_PUBLIC_APPOINTMENT_REQUEST';
export const ADD_PUBLIC_APPOINTMENT_SUCCESS = 'ADD_PUBLIC_APPOINTMENT_SUCCESS';
export const ADD_PUBLIC_APPOINTMENT_FAIL = 'ADD_PUBLIC_APPOINTMENT_FAIL';
export const ADD_PUBLIC_APPOINTMENT_RESET = 'ADD_PUBLIC_APPOINTMENT_RESET';

export const GET_PUBLIC_APPOINTMENT_REQUEST = 'GET_PUBLIC_APPOINTMENT_REQUEST';
export const GET_PUBLIC_APPOINTMENT_SUCCESS = 'GET_PUBLIC_APPOINTMENT_SUCCESS';
export const GET_PUBLIC_APPOINTMENT_FAIL = 'GET_PUBLIC_APPOINTMENT_FAIL';
export const GET_PUBLIC_APPOINTMENT_RESET = 'GET_PUBLIC_APPOINTMENT_RESET';

export const DELETE_APPOINTMENT_REQUEST = 'DELETE_APPOINTMENT_REQUEST';
export const DELETE_APPOINTMENT_SUCCESS = 'DELETE_APPOINTMENT_SUCCESS';
export const DELETE_APPOINTMENT_FAIL = 'DELETE_APPOINTMENT_FAIL';
export const DELETE_APPOINTMENT_RESET = 'DELETE_APPOINTMENT_RESET';

export const DELETE_SLOT_REQUEST = 'DELETE_SLOT_REQUEST';
export const DELETE_SLOT_SUCCESS = 'DELETE_SLOT_SUCCESS';
export const DELETE_SLOT_FAIL = 'DELETE_SLOT_FAIL';
export const DELETE_SLOT_RESET = 'DELETE_SLOT_RESET';

export const UPDATE_APPOINTMENT_REQUEST = 'UPDATE_APPOINTMENT_REQUEST';
export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAIL = 'UPDATE_APPOINTMENT_FAIL';
export const UPDATE_APPOINTMENT_RESET = 'UPDATE_APPOINTMENT_RESET';

export const UPDATE_APPOINTMENT_STATUS_REQUEST = 'UPDATE_APPOINTMENT_STATUS_REQUEST';
export const UPDATE_APPOINTMENT_STATUS_SUCCESS = 'UPDATE_APPOINTMENT_STATUS_SUCCESS';
export const UPDATE_APPOINTMENT_STATUS_FAIL = 'UPDATE_APPOINTMENT_STATUS_FAIL';
export const UPDATE_APPOINTMENT_STATUS_RESET = 'UPDATE_APPOINTMENT_STATUS_RESET';

export const APPOINTMENT_STATUS_LIST_REQUEST = 'APPOINTMENT_STATUS_LIST_REQUEST';
export const APPOINTMENT_STATUS_LIST_SUCCESS = 'APPOINTMENT_STATUS_LIST_SUCCESS';
export const APPOINTMENT_STATUS_LIST_FAIL = 'APPOINTMENT_STATUS_LIST_FAIL';
export const APPOINTMENT_STATUS_LIST_RESET = 'APPOINTMENT_STATUS_LIST_RESET';

export const APPOINTMENT_AVAILIBILITY_REQUEST = 'APPOINTMENT_AVAILIBILITY_REQUEST';
export const APPOINTMENT_AVAILIBILITY_SUCCESS = 'APPOINTMENT_AVAILIBILITY_SUCCESS';
export const APPOINTMENT_AVAILIBILITY_FAIL = 'APPOINTMENT_AVAILIBILITY_FAIL';
export const APPOINTMENT_AVAILIBILITY_RESET = 'APPOINTMENT_AVAILIBILITY_RESET';

export const GET_BUSINESS_REQUEST = 'GET_BUSINESS_REQUEST';
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_FAIL = 'GET_BUSINESS_FAIL';
export const GET_BUSINESS_RESET = 'GET_BUSINESS_RESET';

export const CHANGE_APPOINTMENT_REQUEST = 'CHANGE_APPOINTMENT_REQUEST';
export const CHANGE_APPOINTMENT_SUCCESS = 'CHANGE_APPOINTMENT_SUCCESS';
export const CHANGE_APPOINTMENT_FAIL = 'CHANGE_APPOINTMENT_FAIL';
export const CHANGE_APPOINTMENT_RESET = 'CHANGE_APPOINTMENT_RESET';

export const GET_ACTIVITY_REQUEST = 'GET_ACTIVITY_REQUEST';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_FAIL = 'GET_ACTIVITY_FAIL';
export const GET_ACTIVITY_RESET = 'GET_ACTIVITY_RESET';

export const GET_STYLIST_REQUEST = 'GET_STYLIST_REQUEST';
export const GET_STYLIST_SUCCESS = 'GET_STYLIST_SUCCESS';
export const GET_STYLIST_FAIL = 'GET_STYLIST_FAIL';
export const GET_STYLIST_RESET = 'GET_STYLIST_RESET';
