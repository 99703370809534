import React from "react";
import Modal from "../../components/Modal";
import ModalButton from "../../components/Modal/ModalButton";
import ModalForm from "../../components/Modal/ModalForm";
import ModalHeading from "../../components/Modal/ModalHeading";

const DeleteUser = ({ data }) => {
  const { modalDeleteState, onDeleteUserClose, onDeleteHandler } = data;

  return (
    <Modal show={modalDeleteState}>
      <ModalHeading heading="Delete User" onClose={onDeleteUserClose} />
      <ModalForm>
        <p className="modal__text">
          Are you sure you want to delete this User?
        </p>
        <ModalButton
          varient="danger"
          label="Delete"
          icon="delete"
          onClick={onDeleteHandler}
        />
      </ModalForm>
    </Modal>
  );
};

export default DeleteUser;
