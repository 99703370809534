export const ATTENDANCE_LIST_REQUEST = 'ATTENDANCE_LIST_REQUEST';
export const ATTENDANCE_LIST_SUCCESS = 'ATTENDANCE_LIST_SUCCESS';
export const ATTENDANCE_LIST_FAIL = 'ATTENDANCE_LIST_FAIL';
export const ATTENDANCE_LIST_RESET = 'ATTENDANCE_LIST_RESET';

export const CHECKINOUT_REQUEST = 'CHECKINOUT_REQUEST';
export const CHECKINOUT_SUCCESS = 'CHECKINOUT_SUCCESS';
export const CHECKINOUT_FAIL = 'CHECKINOUT_FAIL';
export const CHECKINOUT_RESET = 'CHECKINOUT_RESET';

export const DELETE_CHECKIN_REQUEST = 'DELETE_CHECKIN_REQUEST';
export const DELETE_CHECKIN_SUCCESS = 'DELETE_CHECKIN_SUCCESS';
export const DELETE_CHECKIN_FAIL = 'DELETE_CHECKIN_FAIL';
export const DELETE_CHECKIN_RESET = 'DELETE_CHECKIN_RESET';

export const GET_STYLIST_AVAILABILITYS_REQUEST = 'GET_STYLIST_AVAILABILITYS_REQUEST';
export const GET_STYLIST_AVAILABILITYS_SUCCESS = 'GET_STYLIST_AVAILABILITYS_SUCCESS';
export const GET_STYLIST_AVAILABILITYS_FAIL = 'GET_STYLIST_AVAILABILITYS_FAIL';
export const GET_STYLIST_AVAILABILITYS_RESET = 'GET_STYLIST_AVAILABILITYS_RESET';

export const ADD_STYLIST_AVAILABILITY_REQUEST = 'ADD_STYLIST_AVAILABILITY_REQUEST';
export const ADD_STYLIST_AVAILABILITY_SUCCESS = 'ADD_STYLIST_AVAILABILITY_SUCCESS';
export const ADD_STYLIST_AVAILABILITY_FAIL = 'ADD_STYLIST_AVAILABILITY_FAIL';
export const ADD_STYLIST_AVAILABILITY_RESET = 'ADD_STYLIST_AVAILABILITY_RESET';

export const TOTAL_STYLIST_AVAILABILITY_REQUEST = 'TOTAL_STYLIST_AVAILABILITY_REQUEST';
export const TOTAL_STYLIST_AVAILABILITY_SUCCESS = 'TOTAL_STYLIST_AVAILABILITY_SUCCESS';
export const TOTAL_STYLIST_AVAILABILITY_FAIL = 'TOTAL_STYLIST_AVAILABILITY_FAIL';
export const TOTAL_STYLIST_AVAILABILITY_RESET = 'TOTAL_STYLIST_AVAILABILITY_RESET';
