export const GET_ALL_COUPONS_BOOKB_REQUEST = 'GET_ALL_COUPONS_BOOKB_REQUEST';
export const GET_ALL_COUPONS_BOOKB_SUCCESS = 'GET_ALL_COUPONS_BOOKB_SUCCESS';
export const GET_ALL_COUPONS_BOOKB_FAIL = 'GET_ALL_COUPONS_BOOKB_FAIL';
export const GET_ALL_COUPONS_BOOKB_RESET = 'GET_ALL_COUPONS_BOOKB_RESET';

export const ADD_COUPON_BOOKB_REQUEST = 'ADD_COUPON_BOOKB_REQUEST';
export const ADD_COUPON_BOOKB_SUCCESS = 'ADD_COUPON_BOOKB_SUCCESS';
export const ADD_COUPON_BOOKB_FAIL = 'ADD_COUPON_BOOKB_FAIL';
export const ADD_COUPON_BOOKB_RESET = 'ADD_COUPON_BOOKB_RESET';

export const ACTIVATE_COUPON_BOOKB_REQUEST = 'ACTIVATE_COUPON_BOOKB_REQUEST';
export const ACTIVATE_COUPON_BOOKB_SUCCESS = 'ACTIVATE_COUPON_BOOKB_SUCCESS';
export const ACTIVATE_COUPON_BOOKB_FAIL = 'ACTIVATE_COUPON_BOOKB_FAIL';
export const ACTIVATE_COUPON_BOOKB_RESET = 'ACTIVATE_COUPON_BOOKB_RESET';

export const DELETE_COUPON_BOOKB_REQUEST = 'DELETE_COUPON_BOOKB_REQUEST';
export const DELETE_COUPON_BOOKB_SUCCESS = 'DELETE_COUPON_BOOKB_SUCCESS';
export const DELETE_COUPON_BOOKB_FAIL = 'DELETE_COUPON_BOOKB_FAIL';
export const DELETE_COUPON_BOOKB_RESET = 'DELETE_COUPON_BOOKB_RESET';

export const VERIFY_COUPON_BOOKB_REQUEST = 'VERIFY_COUPON_BOOKB_REQUEST';
export const VERIFY_COUPON_BOOKB_SUCCESS = 'VERIFY_COUPON_BOOKB_SUCCESS';
export const VERIFY_COUPON_BOOKB_FAIL = 'VERIFY_COUPON_BOOKB_FAIL';
export const VERIFY_COUPON_BOOKB_RESET = 'VERIFY_COUPON_BOOKB_RESET';

export const GET_ADMIN_COUPONS_BOOKB_REQUEST = 'GET_ADMIN_COUPONS_BOOKB_REQUEST';
export const GET_ADMIN_COUPONS_BOOKB_SUCCESS = 'GET_ADMIN_COUPONS_BOOKB_SUCCESS';
export const GET_ADMIN_COUPONS_BOOKB_FAIL = 'GET_ADMIN_COUPONS_BOOKB_FAIL';
export const GET_ADMIN_COUPONS_BOOKB_RESET = 'GET_ADMIN_COUPONS_BOOKB_RESET';

export const GET_ACTIVE_COUPONS_BOOKB_REQUEST = 'GET_ACTIVE_COUPONS_BOOKB_REQUEST';
export const GET_ACTIVE_COUPONS_BOOKB_SUCCESS = 'GET_ACTIVE_COUPONS_BOOKB_SUCCESS';
export const GET_ACTIVE_COUPONS_BOOKB_FAIL = 'GET_ACTIVE_COUPONS_BOOKB_FAIL';
export const GET_ACTIVE_COUPONS_BOOKB_RESET = 'GET_ACTIVE_COUPONS_BOOKB_RESET';