
import React from 'react';
import Styles from './RewardScreen.module.css'

const Modal = ({ isOpen, onClose, title, content }) => {
    if (!isOpen) return null;

    return (
        <div className={Styles.overlay}>
            <div className={Styles.modal}>
                <div className={Styles.header}>
                    <h2>{title}</h2>
                    <button className={Styles.closeButton} onClick={onClose}>✕</button>
                </div>
                <div className={Styles.body}>
                    <p>{content}</p>
                </div>
                <button className={Styles.closeActionButton} onClick={onClose}>Close</button>
            </div>
        </div>
    );
};
export default Modal;
