export const GET_SESSION_REPORT_REQUEST = 'GET_SESSION_REPORT_REQUEST';
export const GET_SESSION_REPORT_SUCCESS = 'GET_SESSION_REPORT_SUCCESS';
export const GET_SESSION_REPORT_FAIL = 'GET_SESSION_REPORT_FAIL';
export const GET_SESSION_REPORT_RESET = 'GET_SESSION_REPORT_RESET';

export const GET_CURRENT_YEAR_REPORT_REQUEST = 'GET_CURRENT_YEAR_REPORT_REQUEST';
export const GET_CURRENT_YEAR_REPORT_SUCCESS = 'GET_CURRENT_YEAR_REPORT_SUCCESS';
export const GET_CURRENT_YEAR_REPORT_FAIL = 'GET_CURRENT_YEAR_REPORT_FAIL';
export const GET_CURRENT_YEAR_REPORT_RESET = 'GET_CURRENT_YEAR_REPORT_RESET';

export const GET_APPOINTMENT_REPORT_REQUEST = 'GET_APPOINTMENT_REPORT_REQUEST';
export const GET_APPOINTMENT_REPORT_SUCCESS = 'GET_APPOINTMENT_REPORT_SUCCESS';
export const GET_APPOINTMENT_REPORT_FAIL = 'GET_APPOINTMENT_REPORT_FAIL';
export const GET_APPOINTMENT_REPORT_RESET = 'GET_APPOINTMENT_REPORT_RESET';

export const GET_RECENT_APPOINTMENT_REPORT_REQUEST = 'GET_RECENT_APPOINTMENT_REPORT_REQUEST';
export const GET_RECENT_APPOINTMENT_REPORT_SUCCESS = 'GET_RECENT_APPOINTMENT_REPORT_SUCCESS';
export const GET_RECENT_APPOINTMENT_REPORT_FAIL = 'GET_RECENT_APPOINTMENT_REPORT_FAIL';
export const GET_RECENT_APPOINTMENT_REPORT_RESET = 'GET_RECENT_APPOINTMENT_REPORT_RESET';

export const GET_UPCOMING_APPOINTMENT_REPORT_REQUEST = 'GET_UPCOMING_APPOINTMENT_REPORT_REQUEST';
export const GET_UPCOMING_APPOINTMENT_REPORT_SUCCESS = 'GET_UPCOMING_APPOINTMENT_REPORT_SUCCESS';
export const GET_UPCOMING_APPOINTMENT_REPORT_FAIL = 'GET_UPCOMING_APPOINTMENT_REPORT_FAIL';
export const GET_UPCOMING_APPOINTMENT_REPORT_RESET = 'GET_UPCOMING_APPOINTMENT_REPORT_RESET';

export const GET_ADMIN_DASHBOARD_REPORT_REQUEST = 'GET_ADMIN_DASHBOARD_REPORT_REQUEST';
export const GET_ADMIN_DASHBOARD_REPORT_SUCCESS = 'GET_ADMIN_DASHBOARD_REPORT_SUCCESS';
export const GET_ADMIN_DASHBOARD_REPORT_FAIL = 'GET_ADMIN_DASHBOARD_REPORT_FAIL';
export const GET_ADMIN_DASHBOARD_REPORT_RESET = 'GET_ADMIN_DASHBOARD_REPORT_RESET';

export const GET_SALON_APPOINTMENT_REPORT_REQUEST = 'GET_SALON_APPOINTMENT_REPORT_REQUEST';
export const GET_SALON_APPOINTMENT_REPORT_SUCCESS = 'GET_SALON_APPOINTMENT_REPORT_SUCCESS';
export const GET_SALON_APPOINTMENT_REPORT_FAIL = 'GET_SALON_APPOINTMENT_REPORT_FAIL';
export const GET_SALON_APPOINTMENT_REPORT_RESET = 'GET_SALON_APPOINTMENT_REPORT_RESET';

export const GET_SALON_SUBSCRIPTION_REPORT_REQUEST = 'GET_SALON_SUBSCRIPTION_REPORT_REQUEST';
export const GET_SALON_SUBSCRIPTION_REPORT_SUCCESS = 'GET_SALON_SUBSCRIPTION_REPORT_SUCCESS';
export const GET_SALON_SUBSCRIPTION_REPORT_FAIL = 'GET_SALON_SUBSCRIPTION_REPORT_FAIL';
export const GET_SALON_SUBSCRIPTION_REPORT_RESET = 'GET_SALON_SUBSCRIPTION_REPORT_RESET';