export const GET_ALL_SERVICE_REQUEST = 'GET_ALL_SERVICE_REQUEST';
export const GET_ALL_SERVICE_SUCCESS = 'GET_ALL_SERVICE_SUCCESS';
export const GET_ALL_SERVICE_FAIL = 'GET_ALL_SERVICE_FAIL';
export const GET_ALL_SERVICE_RESET = 'GET_ALL_SERVICE_RESET';

export const GET_ALL_ENABLE_SERVICE_REQUEST = 'GET_ALL_ENABLE_SERVICE_REQUEST';
export const GET_ALL_ENABLE_SERVICE_SUCCESS = 'GET_ALL_ENABLE_SERVICE_SUCCESS';
export const GET_ALL_ENABLE_SERVICE_FAIL = 'GET_ALL_ENABLE_SERVICE_FAIL';
export const GET_ALL_ENABLE_SERVICE_RESET = 'GET_ALL_ENABLE_SERVICE_RESET';

export const ADD_SERVICE_REQUEST = 'ADD_SERVICE_REQUEST';
export const ADD_SERVICE_SUCCESS = 'ADD_SERVICE_SUCCESS';
export const ADD_SERVICE_FAIL = 'ADD_SERVICE_FAIL';
export const ADD_SERVICE_RESET = 'ADD_SERVICE_RESET';

export const CHANGE_SERVICE_STATUS_REQUEST = 'CHANGE_SERVICE_STATUS_REQUEST';
export const CHANGE_SERVICE_STATUS_SUCCESS = 'CHANGE_SERVICE_STATUS_SUCCESS';
export const CHANGE_SERVICE_STATUS_FAIL = 'CHANGE_SERVICE_STATUS_FAIL';
export const CHANGE_SERVICE_STATUS_RESET = 'CHANGE_SERVICE_STATUS_RESET';

export const DELETE_SERVICE_REQUEST = 'DELETE_SERVICE_REQUEST';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const DELETE_SERVICE_FAIL = 'DELETE_SERVICE_FAIL';
export const DELETE_SERVICE_RESET = 'DELETE_SERVICE_RESET';


export const GET_ALL_SERVICE_CATEGORY_REQUEST = 'GET_ALL_SERVICE_CATEGORY_REQUEST';
export const GET_ALL_SERVICE_CATEGORY_SUCCESS = 'GET_ALL_SERVICE_CATEGORY_SUCCESS';
export const GET_ALL_SERVICE_CATEGORY_FAIL = 'GET_ALL_SERVICE_CATEGORY_FAIL';
export const GET_ALL_SERVICE_CATEGORY_RESET = 'GET_ALL_SERVICE_CATEGORY_RESET';

export const ADD_SERVICE_CATEGORY_REQUEST = 'ADD_SERVICE_CATEGORY_REQUEST';
export const ADD_SERVICE_CATEGORY_SUCCESS = 'ADD_SERVICE_CATEGORY_SUCCESS';
export const ADD_SERVICE_CATEGORY_FAIL = 'ADD_SERVICE_CATEGORY_FAIL';
export const ADD_SERVICE_CATEGORY_RESET = 'ADD_SERVICE_CATEGORY_RESET';

export const SERVICE_CATEGORY_STATUS_REQUEST = 'SERVICE_CATEGORY_STATUS_REQUEST';
export const SERVICE_CATEGORY_STATUS_SUCCESS = 'SERVICE_CATEGORY_STATUS_SUCCESS';
export const SERVICE_CATEGORY_STATUS_FAIL = 'SERVICE_CATEGORY_STATUS_FAIL';
export const SERVICE_CATEGORY_STATUS_RESET = 'SERVICE_CATEGORY_STATUS_RESET';

export const DELETE_SERVICE_CATEGORY_REQUEST = 'DELETE_SERVICE_CATEGORY_REQUEST';
export const DELETE_SERVICE_CATEGORY_SUCCESS = 'DELETE_SERVICE_CATEGORY_SUCCESS';
export const DELETE_SERVICE_CATEGORY_FAIL = 'DELETE_SERVICE_CATEGORY_FAIL';
export const DELETE_SERVICE_CATEGORY_RESET = 'DELETE_SERVICE_CATEGORY_RESET';



export const GET_ALL_ENABLE_SUB_SERVICE_REQUEST = 'GET_ALL_ENABLE_SUB_SERVICE_REQUEST';
export const GET_ALL_ENABLE_SUB_SERVICE_SUCCESS = 'GET_ALL_ENABLE_SUB_SERVICE_SUCCESS';
export const GET_ALL_ENABLE_SUB_SERVICE_FAIL = 'GET_ALL_ENABLE_SUB_SERVICE_FAIL';
export const GET_ALL_ENABLE_SUB_SERVICE_RESET = 'GET_ALL_ENABLE_SUB_SERVICE_RESET';


export const UPDATE_RANK_SERVICE_REQUEST='UPDATE_RANK_SERVICE_REQUEST';
export const UPDATE_RANK_SERVICE_SUCCESS='UPDATE_RANK_SERVICE_SUCCESS';
export const UPDATE_RANK_SERVICE_FAIL='UPDATE_RANK_SERVICE_FAIL';
export const UPDATE_RANK_SERVICE_RESET='UPDATE_RANK_SERVICE_RESET';