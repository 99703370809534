export const GET_USER_BY_WAIER_LIABILITY_REQUEST = 'GET_USER_BY_WAIER_LIABILITY_REQUEST';
export const GET_USER_BY_WAIER_LIABILITY_SUCCESS = 'GET_USER_BY_WAIER_LIABILITY_SUCCESS';
export const GET_USER_BY_WAIER_LIABILITY_FAIL = 'GET_USER_BY_WAIER_LIABILITY_FAIL';
export const GET_USER_BY_WAIER_LIABILITY_RESET = 'GET_USER_BY_WAIER_LIABILITY_RESET';


export const GET_NEW_INDEPENT_AGREEMENT_REQUEST = 'GET_NEW_INDEPENT_AGREEMENT_REQUEST';
export const GET_NEW_INDEPENT_AGREEMENT_SUCCESS = 'GET_NEW_INDEPENT_AGREEMENT_SUCCESS';
export const GET_NEW_INDEPENT_AGREEMENT_FAIL = 'GET_NEW_INDEPENT_AGREEMENT_FAIL';
export const GET_NEW_INDEPENT_AGREEMENT_RESET = 'GET_NEW_INDEPENT_AGREEMENT_RESET';


export const CREATE_NEW_INDEPENT_AGREEMENT_REQUEST = 'CREATE_NEW_INDEPENT_AGREEMENT_REQUEST';
export const CREATE_NEW_INDEPENT_AGREEMENT_SUCCESS = 'CREATE_NEW_INDEPENT_AGREEMENT_SUCCESS';
export const CREATE_NEW_INDEPENT_AGREEMENT_FAIL = 'CREATE_NEW_INDEPENT_AGREEMENT_FAIL';
export const CREATE_NEW_INDEPENT_AGREEMENT_RESET = 'CREATE_NEW_INDEPENT_AGREEMENT_RESET';
