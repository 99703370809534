import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import { io } from 'socket.io-client';
import Navigation from './navigation';
import './App.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { ToastContainer } from 'react-toastify';
import { getUserByTokenAction } from './redux/actions/userActions';
import { getAllEnabledSalonListAction } from './redux/actions/salonActions';
import { BASE_SOCKET_URL } from './redux/actions/ip';
import CongratulationModal from './components/ConragatulationModal/CongratulationModal';


const App = () => {
	const dispatch = useDispatch();
	const [socket, setSocket] = React.useState(null);
	const userLogin = useSelector((state) => state.userLogin);
	const userData = useSelector((state) => state.getUserInfo);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [coins, setCoins] = useState(Number);
	
	const handleCloseModal = () => {
        setIsModalOpen(false); 
    };

	useEffect(() => {
		if (
			userLogin &&
			userLogin.userInfo &&
			userLogin.userInfo.status &&
			userData &&
			userData.userInfo &&
			userData.userInfo.data
		) {
			const socket = io(BASE_SOCKET_URL, {
				query: {
					stylist: userData.userInfo.data?._id,
					role: userData.userInfo.data?.role,
					salon: userData.userInfo.data?.salon?._id,
				},
			});
			setSocket(socket);
			socket.on('connection', (data) => {
			
			});

			return () => {
				socket.disconnect();
			};
		}
	}, [userLogin, userData]);

	useEffect(() =>{
		if(socket){
		socket.on('appointment-request', (obj) => {
			if (obj && obj?.message) {
				console.log(obj, 'obj')
				toast.success(obj?.message)
			}
		});
		// socket.on('complete-profile', (data) => {
		// 	console.log(data, 'data')
		// 	console.log('hello its a referal')
		// 	setCoins(100)
		// 	setRewardModal(true)
		// });
	}
	},[socket])

	useEffect(() => {
		const checkFirstLogin = async () => {
			if (!userLogin || !userLogin.userInfo) return;
	
			const userId = userLogin.userInfo.id; 
			const isModalShown = localStorage.getItem(`isFirstLoginModalShown_${userId}`);
	
			if (
				userLogin.userInfo.status &&
				userLogin.userInfo.isFirstLogin &&
				!isModalShown
			) {
				setCoins(50);
				setIsModalOpen(true);
				localStorage.setItem(`isFirstLoginModalShown_${userId}`, 'true'); 
			}
		};
	
		checkFirstLogin();
	}, [userLogin]);

	useEffect(
		() => {
			dispatch(getUserByTokenAction());
			dispatch(getAllEnabledSalonListAction());
		},
		[dispatch]
	);

	return (
		<Fragment>
			<Navigation userLogin={userLogin} socket={socket} />
			<ToastContainer position='bottom-right' />
			{isModalOpen && <CongratulationModal 
				coins={50}
				onClose={handleCloseModal}
            />}
		</Fragment>
	);
};

export default App;